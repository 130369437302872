import * as React from "react";
import Logo from "../../images/logo/logo-200x105.png";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";

const Navigation = ({ menu }) => {
    return (
        <Navbar
            expand="lg"
            sticky="top"
            className="justify-content-between"
            id="main-nav"
        >
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src={Logo}
                        alt="Caffeine and Code"
                        className=""
                        height="26"
                    />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav fill>
                        <Nav.Link href="#top" className="nav-link">
                            Home
                        </Nav.Link>
                        {menu.map((item, idx) => {
                            return (
                                <Nav.Link href={item.href} className="nav-link">
                                    {item.text}
                                </Nav.Link>
                            );
                        })}
                    </Nav>

                    <Nav justify className="menu-social-icon ml-auto">
                        <Nav.Link
                            href="https://www.facebook.com/caffeineandcode/"
                            target="_blank"
                            className="menu-social-link"
                        >
                            <FaFacebookF />
                        </Nav.Link>
                        <Nav.Link
                            href="https://twitter.com/caffeinecodellc"
                            target="_blank"
                        >
                            <FaTwitter />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
