import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
    return (
        <section className="footer" id="footer">
            <Container>
                <Row>
                    <Col lg={4}>
                        <div className="mb-5">
                            <img
                                src="/images/logo-200x105.png"
                                alt="Caffeine and Code Logo"
                                className="footer-logo"
                            />
                            <p class="text-white-50 my-4">
                                Caffeine and Code LLC
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        <div className="text-center">
                            <p className="text-white-50 f-15 mb-0">
                                © 2021, Caffeine and Code LLC
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Footer;
