import * as React from "react";
import SEO from "../components/SEO";
import Navigation from "../components/Nav";
import Footer from "../components/Footer";

import "../../scss/style.scss";
import "animate.css/animate.min.css";

const MainLayout = ({ children, menu = [] }) => {
    return (
        <>
            <SEO title={"Customized web development for your business"} />
            <Navigation menu={menu} />
            <div id="top" />
            {children}
            <Footer />
        </>
    );
};

export default MainLayout;
